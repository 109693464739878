import { env } from "../config/env";

type BaseUrlConfig = {
  useProxy: boolean;
  localPort?: string;
};

function cleanDuplicateHttpPrefix(url: string | undefined): string | undefined {
  if (!url) {
    return undefined;
  }
  return url.startsWith("https://") || url.startsWith("http://")
    ? url.replace("http://", "").replace("https://", "")
    : url;
}

export function getBaseUrl(config?: BaseUrlConfig): string {
  const { useProxy = true, localPort = "3000" } = config ?? {};

  if (typeof window !== "undefined") {
    return window.location.origin;
  }

  const vercelUrl = cleanDuplicateHttpPrefix(env.VERCEL_URL);
  const proxyUrl = cleanDuplicateHttpPrefix(env.LOCAL_PROXY_URL);

  if (vercelUrl) {
    return vercelUrl.startsWith("https://")
      ? vercelUrl
      : `https://${vercelUrl}`;
  }

  if (useProxy && proxyUrl) {
    return `https://${proxyUrl}`;
  }

  return `http://localhost:${localPort}`;
}
