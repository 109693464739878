import {
  createTRPCReact,
  type inferReactQueryProcedureOptions
} from "@trpc/react-query";
import type { AppRouter } from "@watt/api/src";

export const trpcClient = createTRPCReact<AppRouter>({
  abortOnUnmount: true,
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn();
        // TODO: Isn't this invalidating all queries when we do a mutation?
        await opts.queryClient.invalidateQueries();
      }
    }
  }
});

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;

export type {
  RouterInputs,
  RouterOutputs
} from "@watt/api/src";
